<template>
  <CCol sm="12">
    <b-card>
      <b-card-header
        ><h4>{{ title }}</h4></b-card-header
      >
      <b-card-body>
        <l-map
          ref="myMap"
          :center="[46.165063, 7.448974]"
          :zoom="9"
          style="height: 600px;"
        >
          <l-tile-layer
            url="http://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png"
          />
        </l-map>
      </b-card-body>
    </b-card>
  </CCol>
</template>

<script>
import { InfoControl, ReferenceChart, ChoroplethLayer } from "vue-choropleth";

export default {
  components: {
    "l-info-control": InfoControl,
    "l-reference-chart": ReferenceChart,
    "l-choropleth-layer": ChoroplethLayer
  },
  props: {
    title: { type: String, required: true },
    mapData: { type: Array, required: true },
  },
  data() {
    return {
      geojson: null,
      popupName: null,
      data: null,
      colorScale: ["#fee5d9", "#fcae91", "#fb6a4a", "#cb181d"],
      value: {
        key: "evo",
        metric: `%`
      },
      valueOvernights: {
        key: "total",
        metric: this.$t("touristicovernights.recap.overnights")
      },
      valueAirdna: {
        key: "listings",
        metric: this.$t("platforms.listings")
      },
      valueR2: {
        key: "r2_proportion",
        metric: this.$t("secondaryhomes.r2_proportion")
      },
      otherValues: [
        {
          key: "overnights",
          metric: "Nuitées"
        },
        {
          key: "arrivals",
          metric: "Arrivées"
        }
      ],
      otherValuesOvernights: [
        {
          key: "hotellerie",
          metric: this.$t("touristicovernights.recap.hotels")
        },
        {
          key: "parahotellerie",
          metric: this.$t("touristicovernights.recap.para")
        },
        {
          key: "forfaits",
          metric: this.$t("touristicovernights.recap.forfaits")
        }
      ],
      otherValuesAirdna: [
        {
          key: "adr",
          metric: this.$t("platforms.adr")
        },
        {
          key: "reservationDays",
          metric: this.$t("platforms.reservationDays_median")
        },
        {
          key: "occupation",
          metric: this.$t("platforms.occupation_median")
        }
      ],
      otherValuesR2: [
        {
          key: "total_homes",
          metric: this.$t("secondaryhomes.total_homes")
        },
        {
          key: "secodary_homes",
          metric: this.$t("secondaryhomes.secondary_homes")
        }
      ],
      mapOptions: {
        title: "null",
        style: function style() {
          return {
            weight: 4,
            opacity: 0.7,
            color: "#666",
            fillOpacity: 0.3
          };
        }
      },
      onEachFeature: function onEachFeature(feature, layer) {
        var self = this;
        layer.on("mouseover", function(e) {
          console.log(e);
          self.popupName = feature.properties.name;
          console.log(feature.properties.name);
          console.log(e.latlng);
          //open popup
          /* var popup = L.popup()
                        .setLatLng(e.latlng) 
                        .setContent('<h1 style="color: grey; margin-bottom: 0;">'+ feature.properties.Name +'</h1>')
                        .openOn(self.$refs.myMap.mapObject);  */
        });
      }
    };
  },
  async created() {
    this.mapOptions.title = this.title;
    this.data = this.mapData;
    const response = await fetch("/maps/destinations_vs.json");
    const data = await response.json();
    this.geojson = data;
  }
};
</script>

<style lang="scss">
.vue2leaflet-map.leaflet-container.leaflet-fade-anim.leaflet-grab.leaflet-touch-drag {
  height: 600px;
}</style
>>
